import React, { useState } from "react"

//import Breadcrumbs
import CrudContainer from "./CrudContainer"
import {
  getLogsSeller
} from "./graphqlQuery"

const LogsSeller = () => {
  const [statusError, setStatusError] = useState([
    {
      name: "Sucesso",
      value: "success",
    },
    {
      name: "Erros",
      value: "error",
    }
  ])
  
  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "cr_code",
      },
      {
        Header: "Erro do sistema",
        accessor: "ws_error_system",
        minWidth: 800,
      },
      {
        Header: "Status",
        accessor: "ws_status",
      },      
      {
        Header: "Serviço",
        accessor: "sl_service_select",
        visible: true
      },
      {
        Header: "	Data/Hora",
        accessor: "dx_register",
        Cell: ({ value, column, row }) => {          
          return new Intl.DateTimeFormat('pt-BR', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(value);
        },
      },   
      {
        Header: "",
        accessor: "sl_type",
        visible: false
      },   
      {
        Header: "",
        accessor: "tx_infos_request",
        visible: false
      },               
      {
        Header: "Ações",
        accessor: "actions",
        Filter: () => <div style={{ height: "10px" }}></div>,
        Cell: cellContent => (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              backgroundColor: "red",
            }}
          >
            {cellContent}
          </div>
        ),
      },      
    ],
    []
  );

  // Colunas do filtro customizavel
  const columnsFilterCustom = React.useMemo( 
    () => [
    {
      name: "Status do Log",
      type: "select",
      field: "ws_status", 
      options: statusError,
    },   
  ], [statusError]);
  return (
    <CrudContainer
      routePath=""
      queryGet={getLogsSeller}
      queryGetByCode={null}
      querySave={null}
      queryUpdate={null}
      queryDelete={null}
      queryNextCrCode={null}
      queryPreview={null}
      title="Logs | Braile Distribuidora"
      breadcrumbTitle="Consulta"
      breadcrumbItem="Vendedores"
      columnsTable={columns}
      fieldsCustomFilters={columnsFilterCustom}
    />
  );
};

export default LogsSeller;
