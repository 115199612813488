import { contentUrlServer, typeUrlServer } from "src/common/urlServer"
import GenerateForm from "src/components/CRUD/generateComponents"
import { typeCrudListColumns } from "src/components/Common/typeCridList"
import {
  typeCrudCollapse,
  typeCrudForm
} from "src/components/Common/typeCrudForm"
import { Bundle } from "./bundle"
import { ComponentMultUpload } from "./componentMultUpload"
import { dataProduct } from "./type"

// Traz a configuração pré-definida de urls do servidor
const urlServer: typeUrlServer = contentUrlServer.filter(
  item => item.name == "PRODUCTIMAGE"
)[0];

const urlServerLarge: typeUrlServer = contentUrlServer.filter(
  item => item.name == "PRODUCTIMAGELARGE"
)[0];

const FormManufacturer = (props: {
  dataFormDb: dataProduct;
  columnsTable: Array<typeCrudListColumns>;
  nextCrCode: string;
  listItens: any;
}) => {
  let { dataFormDb, columnsTable, nextCrCode, listItens } = props;
  if (nextCrCode) {
    dataFormDb.cr_code = nextCrCode;
  }

  function adapterListSubCategories(): Array<{
    cr_code: string;
    ws_value: string;
    qi_subgroup: Array<{
      cr_code: string;
      ws_name: string;
    }>;
  }> {
    let temp = [{
      cr_code: "00",
      ws_value: "Novo produto",
      qi_subgroup: [{
        cr_code: null,
        ws_name: "Nenhuma",
      }],
    }];
    const list = listItens.filter(item => item.nameList === "ws_subcategories");
    list.length &&
      list.length > 0 &&
      list[0].content.map(item =>
        temp.push({
          cr_code: item.cr_code,
          ws_value: item.ws_value,
          qi_subgroup: item.qi_subgroup,
        })
      );
    return temp;
  }

  const productContent: Array<typeCrudForm> = [
    {
      Header: "Código no Site",
      accessor: "cr_code",
      cols: "4",
      required: false,
    },
    {
      Header: "Código no ERP",
      accessor: "cr_code_erp",
      cols: "4",
      required: false,
    },
    {
      Header: "Descrição",
      accessor: "ws_commercial_description",
      cols: "8",
      required: false,
      disabled: true,
      uniqueRow: true,
    },
    {
      Header: "Subcategoria",
      accessor: "cr_code_subcategory",
      cols: "8",
      required: false,
      listItens: adapterListSubCategories(),
    },
    {
      Header: "Subcateg. Simultânea",
      accessor: "cr_code_tag",
      cols: "8",
      required: true,
      uniqueRow: true,
      listItens: adapterListSubCategories(),
    },
    {
      Header: "Fabricante",
      accessor: "ws_name_manufactures",
      cols: "4",
      required: false,
      disabled: true,
    },
    /* {
      Header: "Part number",
      accessor: "ws_part_number",
      cols: "4",
      required: false,
      disabled: true,
    }, */
    {
      Header: "Bundle",
      accessor: "bf_bundle",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "IPI Venda",
      accessor: "ws_percent_ipi",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Classificação fiscal",
      accessor: "cr_fiscal_classification",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Código origem fiscal",
      accessor: "nb_tax_code_source",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Peso bruto",
      accessor: "ws_gross_weight",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Saldo em estoque",
      accessor: "dc_inventory_balance",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Ativo",
      accessor: "bf_liberation",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Disponível",
      accessor: "bf_active",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Destaque",
      accessor: "bf_highlighted",
      cols: "4",
      required: false,
    },
    {
      Header: "Super Oferta",
      accessor: "bf_hot_deal",
      cols: "4",
      required: false,
    },
    {
      Header: "Novo",
      accessor: "bf_new_product",
      cols: "4",
      required: false,
    },
    {
      Header: "Novo - Data validade",
      accessor: "dt_validate_new",
      cols: "4",
      required: false,
    },
    {
      Header: "Data cadastro",
      accessor: "dx_register",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Data Integr. ERP",
      accessor: "dx_modification",
      cols: "4",
      required: false,
      disabled: true,
    },
  ];

  const salesContent: Array<typeCrudForm> = [
    {
      Header: "Preço à vista",
      accessor: "dc_price_sale_cash",
      cols: "4",
      required: false,
      uniqueRow: true,
      disabled: true,
    },
    {
      Header: "Preço de venda Site RS",
      accessor: "dc_price_sale_rs",
      cols: "4",
      required: false,
      uniqueRow: true,
      disabled: true,
    },
    {
      Header: "Preço cartucho 10 dias RS",
      accessor: "dc_price_sale_cartucho",
      cols: "4",
      required: false,
      uniqueRow: true,
      disabled: true,
    },
    {
      Header: "Preço de venda Site SC",
      accessor: "dc_price_sale_sc",
      cols: "4",
      required: false,
      uniqueRow: true,
      disabled: true,
    },
    {
      Header: "Preço de venda Site PR",
      accessor: "dc_price_sale_pr",
      cols: "4",
      required: false,
      uniqueRow: true,
      disabled: true,
    },
    {
      Header: "Preço especial",
      accessor: "qf_price_special",
      cols: "8",
      uniqueRow: true,
      required: false,
      complementValueFieldEnd: "T23:59:59.000+0000",
      listView: {
        columns: [
          {
            header: "Preço especial",
            accessor: "sl_price_description",
            disabled: true,
            required: false,
          },
          {
            header: "Tipo Moeda",
            accessor: "sl_price_currency",
            disabled: true,
            required: false,
          },
          {
            header: "Valor",
            accessor: "dc_price",
            required: false,
          },
          {
            header: "Validade inicio",
            accessor: "dt_start_period",
            required: false,
            complementValueFieldEnd: "T00:00:00.000+0000",
          },
          {
            header: "Validade final",
            accessor: "dt_end_period",
            required: false,
            complementValueFieldEnd: "T23:59:59.000+0000",
          },
          {
            header: "Habilitar",
            accessor: "bf_emphasis",
            required: false,
          },
        ],
      },
      observation: (
        <>
          <br />
          <b>IMPORTANTE</b> <br />
          <br />
          O preço especial é utilizado no site, ele tem preferência sobre o
          campo "Preço venda site" conforme a UF. O preço especial também é
          usado no Gerenciador, ao incluir produto em Day. Para que o preço
          especial seja utilizado ele deve: 1) estar habilitado no campo acima
          "Preço especial" e 2) a data atual deve estar entre o período da
          validade do preço especial. <br />
          <br />
          Depois que o produto foi incluído no Day, o site usa o preço do Day
          independentemente da validade do preço especial (ou seja, a validade é
          usada apenas ao incluir o produto no Day).
        </>
      ),
    },
    {
      Header: "Preço especial destaque",
      accessor: "bf_highlighted",
      cols: "4",
      required: false,
      observation: (
        <>Se o produto deve aparecer como destaque na página inicial</>
      ),
    },
    // {
    //   Header: "Aplicação de ICMS",
    //   accessor: "bf_apply_icms",
    //   cols: "4",
    //   required: false,
    //   observation: (
    //     <>Quando marcado SIM, a consulta de produto ira procurar nas tabelas fiscais o calculo do ICMS</>
    //   ),
    // },
    {
      Header: "Habilita o percentual de desconto - Diferimento",
      accessor: "bf_discount_percentage",
      cols: "4",
      required: false,
    },
    {
      Header: "Percentual de desconto - Diferimento",
      accessor: "nc_discount_percentage",
      cols: "4",
      required: false,
    },
  ];

  const imageContent: Array<typeCrudForm> = [
    {
      Header: "Imagem principal do produto",
      accessor: "wh_image",
      url: urlServer.urlServer + urlServer.pathServer,
      cols: "8",
      required: false,
      uniqueRow: true,
      urlPathServerFile: urlServer.pathServer
    },
    {
      Header: "Imagens da descrição do produto",
      accessor: "wl_image_list",
      url: urlServerLarge.urlServer + urlServerLarge.pathServer + "/",
      cols: "8",
      required: false,
      uniqueRow: true,
      urlPathServerFile: urlServerLarge.pathServer,
      custom: <>{<ComponentMultUpload dataFormDb={dataFormDb} label={"Imagens da descrição do produto"} />}</>
    },
  ];

  const descriptionContent: Array<typeCrudForm> = [
    {
      Header: "Produto",
      accessor: "cr_code",
      cols: "4",
      required: false,
    },
    {
      Header: "Descrição",
      accessor: "ws_commercial_description",
      cols: "8",
      required: false,
      disabled: true,
    },
    {
      Header: "Editor",
      accessor: "wt_commercial_text",
      cols: "12",
      required: false,
    },
  ];

  const buyTooContent: Array<typeCrudForm> = [
    {
      Header: "Produto",
      accessor: "cr_code",
      cols: "4",
      required: false,
    },
    {
      Header: "Descrição",
      accessor: "ws_commercial_description",
      cols: "8",
      required: false,
      disabled: true,
      observation:
        "Clique e arraste para alterar a ordem dos produtos; para excluir, clique duas vezes sobre a imagem.",
    },
    {
      Header: "Imagens do Produto",
      accessor: "wh_image",
      url: urlServer.urlServer + urlServer.pathServer + "/",
      cols: "8",
      required: false,
      uniqueRow: true,
    },
  ];

  const bundleContent: Array<typeCrudForm> = [
    {
      Header: "Produto",
      accessor: "cr_code",
      cols: "4",
      required: false,
    },
    {
      Header: "Descrição",
      accessor: "ws_commercial_description",
      cols: "8",
      required: false,
      disabled: true,
      observation:
        "Clique e arraste para alterar a ordem dos produtos; para excluir, clique duas vezes sobre a imagem.",
    },
    {
      Header: "",
      accessor: "qs_bundle",
      cols: "4",
      required: false,
      uniqueRow: true,
      custom: <Bundle dataFormDb={dataFormDb} />
    },
  ];

  const showcaseContent: Array<typeCrudForm> = [
    {
      Header: "Destaque no topo",
      accessor: "bf_highlighted_card_top",
      cols: "8",
      required: false,
      uniqueRow: true,
      observation:
        "Marcando está opção como Sim, este produto aparecerá em qualquer vitrine horizontal",
    },
    {
      Header: "Vitrines horizontais",
      accessor: "bf_highlighted_card_horizontal_showcase",
      cols: "8",
      required: false,
      uniqueRow: true,
      observation:
        "Marcando está opção como Sim, este produto aparecerá em qualquer vitrine horizontal",
    },
    {
      Header: "Melhor Oferta na vitrine horizontal",
      accessor: "bf_highlighted_card_horizontal_showcase_best_offer",
      cols: "8",
      required: false,
      uniqueRow: true,
      observation:
        "Marcando está opção como Sim, este produto aparecerá como MELHOR OFERTA em qualquer vitrine horizontal (Primeiro card de Melhor Oferta)",
    }
  ];

  const subtitleForm: Array<typeCrudCollapse> = [
    {
      CollapseContent: productContent,
      CollapseTitle: "Produto",
    },
    {
      CollapseContent: salesContent,
      CollapseTitle: "Preços",
    },
    {
      CollapseContent: imageContent,
      CollapseTitle: "Imagens",
    },
    {
      CollapseContent: descriptionContent,
      CollapseTitle: "Texto Comercial",
    },
    {
      CollapseContent: buyTooContent,
      CollapseTitle: "Compre Também",
    },
    {
      CollapseContent: bundleContent,
      CollapseTitle: "Bundle",
    },
    {
      CollapseContent: showcaseContent,
      CollapseTitle: "Configuração das Vitrines",
    },    
  ];
  return GenerateForm(dataFormDb, subtitleForm);
};

export default FormManufacturer;
