export const getLogsManufacturer =  (
  filter: String,
  limit: Number = 10,
  random: Boolean = false,
  skip: Number = 0,
  sort: String = "{}"
): string => {
  let filter2 = `{"sl_service_select":"bo-ms-manufacturer-node"}`;
  let filter3 = JSON.stringify(filter2);
  return `query 
    {
      getLogEmails(
        filter: {
          filter: ${filter3},
          sort: "${sort}",
          skip: ${skip},  
          limit: ${limit},                      
          random: ${random},
        }
      ) {
          result {
            cr_code
            ws_error_system
            dx_register
            ws_status
            sl_service_select
            tx_infos_request
            sl_type
          }
          rows
          rowsall                          
      }
    }`
}