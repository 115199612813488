import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import DataTable from "src/components/Tables/Table";
import { post } from "src/helpers/api_helper";
import { getOrderByCode, getOrderItensByCodeOrder } from "../../graphqlQuery";
import { LabelCnpj } from "./styled";
import { toUpper } from "lodash";

export const FormOrderItens = (props: {
  dataFormDb: {
    bf_liberation: Boolean;
    cr_code: String;
    ws_name: String;
    wh_image: String;
    ws_abbreviation: String;
    hl_abbreviation_url: String;
  };
}) => {
  const [allDataFormDb, setAllDataFormDb] = useState([]);
  const [allOrder, setOrder] = useState([]);
  const [dcChargePagarme, setDcChargePagarme] = useState("0.00");
  const [globalEncargosUnitario, setGlobalEncargosUnitario] = useState(0);
  const [globalEncargosQuantidadeItens, setGlobalEncargosQuantidadeItens] =
    useState(0);
  // Formatar o número para o formato brasileiro
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
  const boletoOptions = {
    "15": "10 D.D.",
    "35": "28 D.D.",
    "03": "21/35 D.D.",
    "02": "21/28/35 D.D.",
    "53": "14/21/28/35 D.D.",
    "76": "14/21/28/35/42 D.D",
  };

  /* Lógica de requisições BACK END */
  const getDataOrderItens = React.useCallback(async (cr_code: string) => {
    try {
      let response = await post(
        "/graphql",
        {
          query: getOrderItensByCodeOrder(cr_code),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then(response => response)
        .catch(error => alert("Falha ao buscar dados do cliente!"));
      if (response.data[Object.keys(response.data)[0]]) {
        const orderItens = response.data[Object.keys(response.data)[0]];
        setAllDataFormDb(orderItens);
        getDataOrder(String(orderItens[0].cr_code_order));
      } else {
        alert("Falha ao selecionar registro!");
      }
    } catch (e) {
      setAllDataFormDb(null);
      alert(`Falha ao selecionar registro ${cr_code}!`);
    }
  }, []);

  /* Lógica de requisições BACK END */
  const getDataOrder = React.useCallback(async (cr_code: string) => {
    try {
      let response = await post(
        "/graphql",
        {
          query: getOrderByCode(cr_code),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then(response => response)
        .catch(error => alert("Falha ao buscar dados do pedido!"));
      if (response.data[Object.keys(response.data)[0]]) {
        setOrder(response.data[Object.keys(response.data)[0]]);
      } else {
        alert("Falha ao selecionar registro!");
      }
    } catch (e) {
      setOrder(null);
      alert(`Falha ao selecionar registro ${cr_code}!`);
    }
  }, []);

  const getMoedaValue = value => {
    // Primeiro, vamos separar a parte inteira da parte decimal.
    const integerPart = Math.floor(value);
    const decimalPart = (value - integerPart).toFixed(14).slice(2); // slice(2) para remover o "0."

    // Agora, vamos formatar a parte inteira usando toLocaleString para adicionar pontos de milhar.
    const formattedIntegerPart = integerPart.toLocaleString("pt-BR");

    // Finalmente, juntando as partes formatadas
    const formattedValue = `${formattedIntegerPart},${decimalPart}`;

    return formattedValue;
  };

  function convertToNumber(formattedString) {
    const numericString = formattedString.replace(/\./g, "").replace(",", ".");
    return parseFloat(numericString);
  }

  useEffect(() => {
    getDataOrderItens(String(props.dataFormDb.cr_code));
  }, []);

  useEffect(() => {
    if (
      props.dataFormDb["dc_charge_pagarme"] &&
      globalEncargosQuantidadeItens > 0
    ) {
      let encargosTotal = convertToNumber(
        getMoedaValue(props.dataFormDb["dc_charge_pagarme"])
      );
      const total_itens = globalEncargosQuantidadeItens;
      let encargoPorItem = encargosTotal / total_itens;
      //Aqui adiciona o encargo no item
      setGlobalEncargosUnitario(encargoPorItem);
    }
  }, [globalEncargosQuantidadeItens, setGlobalEncargosQuantidadeItens]);

  const formatCondPagamento = () => {
    const installments =
      allOrder["ws_site_type_gateway"] === "boleto"
        ? boletoOptions[allOrder["ws_site_type_installments"]]
        : " - " + allOrder["ws_site_type_installments"] + "x";

    const typePayment =
      allOrder["ws_site_type_gateway"] === "boleto"
        ? ""
        : allOrder["ws_site_type_payment"];
    if (allOrder["ws_site_type_gateway"] === "avista") {
      return toUpper(allOrder["ws_site_type_gateway"]);
    }
    return (
      toUpper(allOrder["ws_site_type_gateway"]) +
      " " +
      typePayment +
      " " +
      installments
    );
  };
  // console.log("allDataFormDb", allDataFormDb);
  // console.log("allOrder", allOrder);
  const columns = [
    /* {
        Header: "#",
        accessor: "",
      }, */
    {
      Header: "Código",
      accessor: "cr_code_product",
    },
    {
      Header: "Produto / Day",
      accessor: "ws_product_name",
      minWidth: "400px",
    },
    {
      Header: "	Qtde.",
      accessor: "ws_amount_format",
      Cell: ({ value, column, row }) => {
        return (
          <div style={{ width: "100%", textAlign: "center" }}>
            <LabelCnpj>{value}</LabelCnpj>
          </div>
        );
      },
    },
    {
      Header: (
        <>
          Vl. Unit.
          <br />
          R$
        </>
      ),
      accessor: "ws_unitary_value_format",
      Cell: ({ value, column, row }) => {
        // Trata os valores do encargo do pedido
        // const ws_amount_format = row?.values?.ws_amount_format ? row.values.ws_amount_format : "0";
        // const encargosTotal = ws_amount_format.replace(/[^\d.-]/g, "");
        // let encargosItem = encargosTotal ? parseFloat(encargosTotal) / parseFloat(dcChargePagarme) : ""

        // // Remove formatação para float para poder calular com o encargo
        // const formattedValue = value;
        // const valueWithoutFormatting = formattedValue.replace(/R\$\s*/g, "").replace(",", ".");
        // const valueUnitary = parseFloat(valueWithoutFormatting);

        // // Arredondando para duas casas decimais e diminuiu o valor do encargo do unitario
        // encargosItem = parseFloat((+encargosItem).toFixed(2));
        // const total = valueUnitary - encargosItem

        return (
          <div style={{ width: "100%", textAlign: "right" }}>
            <LabelCnpj>{value}</LabelCnpj>
          </div>
        );
      },
    },
    {
      Header: (
        <>
          Encargos
          <br />
          R$
        </>
      ),
      accessor: "dc_charge_pagarme",
      Cell: ({ value, column, row }) => {
        let amount = row?.values?.ws_amount_format
          ? row.values.ws_amount_format
          : "0";
        amount = amount.replace(/[^\d.-]/g, "");
        const encargo = allOrder["dc_charge_pagarme"];
        let encargoPorItem = encargo / globalEncargosQuantidadeItens;
        let encargoPorGrupo = encargoPorItem * amount;
        encargoPorGrupo = isNaN(encargoPorGrupo) ? 0 : encargoPorGrupo;
        // console.log("encargoPorGrupo", encargoPorGrupo);
        // Importante: Faz o calculo manualmente POR QUE NÃO TEM SALVO POR ITEM DO PEDIDO!!!
        // Divide o valor total do encargo do pedido pelo valor total dos itens do pedido

        // const ws_amount_format = row?.values?.ws_amount_format ? row.values.ws_amount_format : "0";
        // const encargosTotal = ws_amount_format.replace(/[^\d.-]/g, "");
        // let encargosItem = encargosTotal ? parseFloat(encargosTotal) / parseFloat(dcChargePagarme) : ""

        // // Arredondando para duas casas decimais
        // encargosItem = parseFloat((+encargosItem).toFixed(2));

        // // Formatar o número para o formato brasileiro
        // const formatter = new Intl.NumberFormat('pt-BR', {
        //   style: 'currency',
        //   currency: 'BRL',
        // });

        return (
          <div style={{ width: "100%", textAlign: "right" }}>
            <LabelCnpj>{formatter.format(encargoPorGrupo)}</LabelCnpj>
          </div>
        );
      },
    },
    {
      Header: (
        <>
          Subtotal
          <br />
          R$
        </>
      ),
      accessor: "ws_sum_value_format",
      Cell: ({ value, column, row }) => {
        // Trata os valores do encargo do pedido
        let amount = row?.values?.ws_amount_format
          ? row.values.ws_amount_format
          : "0";
        amount = amount.replace(/[^\d.-]/g, "");

        const globalEncargosUnitarioAdapter = globalEncargosUnitario;
        const unitario = globalEncargosUnitarioAdapter;

        let valueTotal = value.replace(/R\$\s*/g, "").replace(",", ".");
        let total = parseFloat(amount) * unitario;
        total = parseFloat(valueTotal) + total;

        // Arredondando para duas casas decimais
        total = parseFloat((+total).toFixed(2));

        return (
          <div style={{ width: "100%", textAlign: "right" }}>
            <LabelCnpj>
              {globalEncargosUnitario > 0 ? formatter.format(total) : value}
            </LabelCnpj>
          </div>
        );
      },
    },
    {
      Header: "% ICMS",
      accessor: "dc_icms_percentage",
    },
    {
      Header: "CFOP",
      accessor: "ws_operation_code",
    },
    {
      Header: "% IPI",
      accessor: "dc_ipi_percentage",
    },
    {
      Header: (
        <>
          "% ICMS
          <br />
          Subst.
        </>
      ),
      accessor: "dc_perc_icms_subst",
    },
    {
      Header: (
        <>
          % Base
          <br />
          Subst. ICMS
        </>
      ),
      accessor: "dc_perc_base_subst_icms",
    },
    {
      Header: "Vl. Unit. c/ IPI+ST R$",
      accessor: "ws_unitary_value_ipi_st_format",
    },
    {
      Header: "Subtotal R$",
      accessor: "ws_sum_value_fiscal_format",
      Cell: ({ value, column, row }) => {
        // Trata os valores do encargo do pedido
        let amount = row?.values?.ws_amount_format
          ? row.values.ws_amount_format
          : "0";
        amount = amount.replace(/[^\d.-]/g, "");

        const globalEncargosUnitarioAdapter = globalEncargosUnitario;
        const unitario = globalEncargosUnitarioAdapter;

        let valueTotal = value.replace(/R\$\s*/g, "").replace(",", ".");
        let total = parseFloat(amount) * unitario;
        total = parseFloat(valueTotal) + total;

        // Arredondando para duas casas decimais
        total = parseFloat((+total).toFixed(2));

        return (
          <div style={{ width: "100%", textAlign: "right" }}>
            <LabelCnpj>
              {globalEncargosUnitario > 0 ? formatter.format(total) : value}
            </LabelCnpj>
          </div>
        );
      },
    },
    {
      Header: "Total R$",
      accessor: "ws_sum_value_fiscal_forma",
      visible: false,
    },
  ];
  const siteSituation = state => {
    return (
      <>
        {state === "Em processamento" ? (
          <div style={{ color: "yellow" }}>{state}</div>
        ) : (
          <div style={{ color: "green" }}>{state}</div>
        )}
      </>
    );
  };

  const [localFilter, setLocalFilterSearch] = React.useState(["", ""]);
  const [globalFilter, setGlobalFilterSearch] = React.useState("");

  // Faz o calculo de TODAS AS QUANTIDADES de TODOS OS PRODUTOS
  useEffect(() => {
    setDcChargePagarme(allOrder["dc_charge_pagarme"] || "0.00");
    // Faz o calculo de TODAS AS QUANTIDADES de TODOS OS PRODUTOS
    if (allOrder["dc_charge_pagarme"]) {
      let totalPedidoItens = allDataFormDb.reduce((acc, item) => {
        if (item.ws_amount_format) {
          // Trata os valores do encargo do pedido
          const wsAmountFormat = item.ws_amount_format
            ? item.ws_amount_format
            : "0";
          const amountTotal = wsAmountFormat.replace(/[^\d.-]/g, "");

          // Convertendo para um número decimal
          const value = parseFloat(amountTotal);
          // Verificando se o valor é um número e, em seguida, somando-o ao acumulador
          if (!isNaN(value)) {
            return acc + value;
          } else {
            return acc;
          }
        } else {
          return acc;
        }
      }, 0);
      setGlobalEncargosQuantidadeItens(totalPedidoItens);
    }
  }, [allDataFormDb, allOrder]);

  let totalPedidoValue = allDataFormDb.reduce((acc, item) => {
    if (item.ws_sum_value_fiscal_format) {
      // Removendo "R$" e trocando a vírgula por um ponto
      const valueStr = item.ws_sum_value_fiscal_format
        .replace("R$", "")
        .replace(".", "")
        .replace(",", ".");
      // Convertendo para um número decimal
      const value = parseFloat(valueStr);
      // Verificando se o valor é um número e, em seguida, somando-o ao acumulador
      if (!isNaN(value)) {
        return acc + value;
      } else {
        return acc;
      }
    } else {
      return acc;
    }
  }, 0);

  return (
    <>
      <Container fluid>
        <Card>
          <CardBody>
            <Row>
              <Col md="3" style={{ textAlign: "right", fontWeight: "bold" }}>
                Nº pedido ERP
              </Col>
              <Col md="3" style={{ textAlign: "left" }}>
                {allOrder["cr_code_erp"]}
              </Col>
              <Col md="3" style={{ textAlign: "right", fontWeight: "bold" }}>
                Data Cadastro
              </Col>
              <Col md="3" style={{ textAlign: "left" }}>
                {allOrder["dx_register"]}
              </Col>
            </Row>
            <Row>
              <Col md="3" style={{ textAlign: "right", fontWeight: "bold" }}>
                Cliente
              </Col>
              <Col md="3" style={{ textAlign: "left" }}>
                {allOrder["cr_code_client"]} - {allOrder["ws_client"]}
              </Col>
              <Col md="3" style={{ textAlign: "right", fontWeight: "bold" }}>
                Data Envio Email
              </Col>
              <Col md="3" style={{ textAlign: "left" }}>
                {allOrder["dx_register_send_email"]}
              </Col>
            </Row>
            <Row>
              <Col md="3" style={{ textAlign: "right", fontWeight: "bold" }}>
                Vendedor
              </Col>
              <Col md="3" style={{ textAlign: "left" }}>
                {allOrder["ws_seller_name"]}
              </Col>
              <Col md="3" style={{ textAlign: "right", fontWeight: "bold" }}>
                Data Integração ERP
              </Col>
              <Col md="3" style={{ textAlign: "left" }}>
                {allOrder["dx_modification"]}
              </Col>
            </Row>
            <Row>
              <Col md="3" style={{ textAlign: "right", fontWeight: "bold" }}>
                Operação Fiscal
              </Col>
              <Col md="3" style={{ textAlign: "left" }}>
                {allOrder["ws_operation_description"]}
              </Col>
              <Col
                md="3"
                style={{ textAlign: "right", fontWeight: "bold" }}
              ></Col>
              <Col md="3" style={{ textAlign: "left" }}></Col>
            </Row>
            <Row>
              <Col md="3" style={{ textAlign: "right", fontWeight: "bold" }}>
                Tipo de Frete do Cliente
              </Col>
              <Col md="3" style={{ textAlign: "left" }}>
                {allOrder["ws_sl_freight_type"]}
              </Col>
              <Col
                md="3"
                style={{ textAlign: "right", fontWeight: "bold" }}
              ></Col>
              <Col md="3" style={{ textAlign: "left" }}></Col>
            </Row>
            <Row>
              <Col md="3" style={{ textAlign: "right", fontWeight: "bold" }}>
                Tipo Entrega
              </Col>
              <Col md="3" style={{ textAlign: "left" }}>
                {allOrder["ws_site_type_delivery"]}
              </Col>
              <Col
                md="3"
                style={{ textAlign: "right", fontWeight: "bold" }}
              ></Col>
              <Col md="3" style={{ textAlign: "left" }}></Col>
            </Row>
            <Row>
              <Col md="3" style={{ textAlign: "right", fontWeight: "bold" }}>
                Cond. de Pagamento
              </Col>
              <Col md="3" style={{ textAlign: "left" }}>
                {formatCondPagamento()}
              </Col>
              <Col
                md="3"
                style={{ textAlign: "right", fontWeight: "bold" }}
              ></Col>
              <Col md="3" style={{ textAlign: "left" }}></Col>
            </Row>
            <Row>
              <Col md="3" style={{ textAlign: "right", fontWeight: "bold" }}>
                Site Situação
              </Col>
              <Col md="3" style={{ textAlign: "left" }}>
                <i>{siteSituation(allOrder["ws_site_situation"])}</i>
              </Col>
              <Col
                md="3"
                style={{ textAlign: "right", fontWeight: "bold" }}
              ></Col>
              <Col md="3" style={{ textAlign: "left" }}></Col>
            </Row>
            <Row>
              <Col md="3" style={{ textAlign: "right", fontWeight: "bold" }}>
                Site Observação
              </Col>
              <Col md="3" style={{ textAlign: "left" }}>
                {allOrder["ws_site_observation"]}
              </Col>
              <Col
                md="3"
                style={{ textAlign: "right", fontWeight: "bold" }}
              ></Col>
              <Col md="3" style={{ textAlign: "left" }}></Col>
            </Row>
            <Row>
              <Col md="3" style={{ textAlign: "right", fontWeight: "bold" }}>
                Cód. Pagarme
              </Col>
              <Col md="3" style={{ textAlign: "left" }}>
                {allOrder["cr_pagarme"]}
              </Col>
              <Col
                md="3"
                style={{ textAlign: "right", fontWeight: "bold" }}
              ></Col>
              <Col md="3" style={{ textAlign: "left" }}></Col>
            </Row>
            <Row>
              <Col md="3" style={{ textAlign: "right", fontWeight: "bold" }}>
                Encargos Pagarme
              </Col>
              <Col md="3" style={{ textAlign: "left" }}>
                R${" "}
                {allOrder["dc_charge_pagarme"]
                  ? parseFloat(allOrder["dc_charge_pagarme"])
                      .toFixed(2)
                      .replace(".", ",")
                  : "0,00"}
              </Col>
              <Col
                md="3"
                style={{ textAlign: "right", fontWeight: "bold" }}
              ></Col>
              <Col md="3" style={{ textAlign: "left" }}></Col>
            </Row>
            <Row>
              <Col md="3" style={{ textAlign: "right", fontWeight: "bold" }}>
                Total Pedido
              </Col>
              <Col md="3" style={{ textAlign: "left" }}>
                {`R$ ${totalPedidoValue.toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                })}`}
              </Col>
              <Col
                md="3"
                style={{ textAlign: "right", fontWeight: "bold" }}
              ></Col>
              <Col md="3" style={{ textAlign: "left" }}></Col>
            </Row>
          </CardBody>
        </Card>
        <Button onClick={() => alert("E-mail reenviado com sucesso!")}>
          {" "}
          Reenvio de E-mail
        </Button>
        <br />
        <br />
        <br />
        <DataTable
          columns={columns}
          dataTable={allDataFormDb ? allDataFormDb : []}
          headFilters={true}
          handleNewRegister={null}
          optionsNewRegister={[]}
          loadingEntitie={false}
          mergeCollumnsRows={() => {}}
          visibleNewRegister={true}
          // columnsCountTotal={columnsCountTotal}
          fetchData={() => {}}
          pageCount={1}
          pageIndexSet={1}
          setGlobalFilterSearch={setGlobalFilterSearch}
          setLocalFilterSearch={setLocalFilterSearch}
          setGlobalFilterCustom={null}
          setLimit={null}
          isReadyToExport={false}
          dataFilter={[]}
          query={null}
          loadingDataFilter={false}
          limit={0}
        />
      </Container>
    </>
  );
};
